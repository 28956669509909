export default {
  LOGIN_ACCOUNT: '登陆账户',
  USER_ID: '用户ID',
  PASSWORD: '密码',
  PLEASE_INPUT_PASSWORD: '请输入密码',
  SIGN_IN: '登录',
  FORGET_PASSWORD: '忘记密码？',
  FORGET_YOUR_PASSWORD: '找回密码',
  FORGET_PASSWORD_INTRO: '请您联系以下管理员重置密码',
  FORGET_PASSWORD_PLACEHOLDER1: '请您输入帐号',
  FORGET_PASSWORD_PLACEHOLDER2: '请您输入邮箱',
  API_DASHBOARD: 'API 概览',
  API_DOCUMENT: 'API 文档',
  EPO_CENTER: 'ePO 中心',
  MASTER_DATA: '主数据',
  PRODUCT: '产品',
  PRODUCT_DATA: '产品数据',
  PRODUCT_PRICE: '产品价格',
  PRODUCT_STOCK: '产品库存',
  PRODUCT_GTS: '产品 GTS',
  PRODUCT_VARIANCE: '产品变量',
  HIGH_RISK: '高风险',
  CUSTOMER_PROFILE: '客户档案',
  SETTING: '设置',
  USER_MANAGEMENT: '用户管理',
  ROLE_AUTHORITY_MANAGEMENT: '角色及权限管理',
  SUBMIT: '提交',
  RESET_PASSWORD: '重置密码',
  INPUT_YOUR_PASSWORD: '请输入您的新密码',
  PROMOTIONS: '促销',
  CUSTOMER_DASHBOARD: '客户概览',
  CUSTOMER_THRESHOLD: '客户阈值信息',
  SAVE: '保存',
  DONE: '完成',
};
