// import axios from 'axios';
// import { history } from '@@/core/history';
//
// const baseURL = process.env.REQUEST_BASE_URL;
// import { getCookie } from '../utils/public';
// import { message } from 'antd';
// export default function request(options: any) {
//   let response;
//   try {
//     return new Promise(function (resolve, reject) {
//       axios({
//         timeout: 15000,
//         headers: {
//           userToken: getCookie('userToken'),
//           'Content-Type': 'application/json',
//           'Access-Control-Allow-Origin': '*',
//           Accept: 'application/json',
//         },
//         baseURL,
//         ...options,
//       })
//         .then((response) => {
//           console.log('response', response);
//           // resolve(response);
//           if (response.data.code == 0) {
//             resolve(response);
//           } else {
//             resolve(response);
//             if (response.data.errorMsg) {
//               message.warn(response.data.errorMsg);
//             } else {
//               message.warn(response.data.msg);
//             }
//           }
//         })
//         .catch((response: any) => {
//           let data = {
//             data: {
//               code: 1001,
//             },
//           };
//           resolve(data);
//           if (response == '') {
//             history.push('/api');
//           } else {
//             message.error('请求错误' + response);
//           }
//         });
//     });
//   } catch (err) {
//     message.error(err);
//   }
// }

import axios from 'axios';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { message, Spin } from 'antd';
import { delCookie, getCookie } from '../utils/public';
import { history } from 'umi';

const baseURL = process.env.REQUEST_BASE_URL;
const Axios = axios.create({
  baseURL: baseURL, // 设置请求的base url
  timeout: 300000, // 设置超时时长
});
// const userToken = getCookie(document.domain + 'userToken');
// delCookie(document.domain + 'userToken');

// 设置post请求头
Axios.defaults.headers.post['Content-Type'] = 'application/json';

// 当前正在请求的数量
let requestCount = 0;

// 显示loading
function showLoading() {
  if (requestCount === 0) {
    var dom = document.createElement('div');
    dom.setAttribute('id', 'loading');
    document.body.appendChild(dom);
    ReactDOM.render(<Spin tip="loading..." size="large" />, dom);
  }
  requestCount++;
}

// 隐藏loading
function hideLoading() {
  requestCount--;
  if (requestCount === 0) {
    document.body.removeChild(document.getElementById('loading'));
  }
}

// 请求前拦截
Axios.interceptors.request.use(
  (config) => {
    // config.headers['userToken'] = userToken;
    // requestCount为0，才创建loading, 避免重复创建
    if (config.headers.isLoading !== false) {
      //showLoading();
    }
    return config;
  },
  (err) => {
    // 判断当前请求是否设置了不显示Loading
    if (err.config.headers.isLoading !== false) {
      //hideLoading();
    }
    return Promise.reject(err);
  },
);

// 返回后拦截
Axios.interceptors.response.use(
  (res) => {
    // console.log(res);
    // 判断当前请求是否设置了不显示Loading
    if (res.config.headers.isLoading !== false) {
      //hideLoading();
    }
    return res;
  },
  (err) => {
    if (err.config.headers.isLoading !== false) {
      //hideLoading();
    }
    if (err.message === 'Network Error') {
      message.warning('Network Error! Please try login again!');
      // history.push('/login');
      return Promise.reject(err);
    }
    if (err.code === 'ECONNABORTED') {
      message.warning('Server Response Timeout! Please try login again!');
      // history.push('/login');
      return Promise.reject(err);
    }
    if (err.response) {
      console.log(err.response);
      message.error(err.response?.statusText + '! Please try login again!');
      // history.push('/login');
    } else {
      message.error('Unexpected Exception occurred! Please try login again!');
      // history.push('/login');
    }
    return Promise.reject(err);
  },
);

// 把组件引入，并定义成原型属性方便使用
Component.prototype.$axios = Axios;

export default Axios;
