// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/app/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index.tsx')}),
    "routes": [
      {
        "path": "/404",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "API Dashboard",
        "title": "Error 404"
      },
      {
        "path": "/Sample",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Sample' */'@/pages/Sample.tsx')})
      },
      {
        "path": "/blank",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__blank' */'@/pages/blank.tsx')})
      },
      {
        "path": "/customer/createCustomer",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__createCustomer' */'@/pages/customer/createCustomer.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "Customer Profile",
        "title": "Create Customer"
      },
      {
        "path": "/customer",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__index' */'@/pages/customer/index.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "Customer Profile",
        "title": "Customer Profile"
      },
      {
        "path": "/customer/list",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__list' */'@/pages/customer/list.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "Customer Profile",
        "title": "Customer Profile"
      },
      {
        "path": "/dashboard",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dashboard__index' */'@/pages/dashboard/index.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "API Dashboard",
        "title": "API DASHBOARD"
      },
      {
        "path": "/dashboard_customer",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dashboard_customer__index' */'@/pages/dashboard_customer/index.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "API Dashboard",
        "title": "CUSTOMER DASHBOARD"
      },
      {
        "path": "/document/docs",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__document__docs' */'@/pages/document/docs.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "API Document",
        "title": "API DOCUMENT"
      },
      {
        "path": "/document",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__document__index' */'@/pages/document/index.tsx')})
      },
      {
        "path": "/document/overview",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__document__overview' */'@/pages/document/overview.tsx')})
      },
      {
        "path": "/epo/asn",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__epo__asn' */'@/pages/epo/asn.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "ePO Center",
        "title": "ePO CENTER"
      },
      {
        "path": "/epo/delivery",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__epo__delivery' */'@/pages/epo/delivery.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "ePO Center",
        "title": "Delivery"
      },
      {
        "path": "/epo/detail",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__epo__detail' */'@/pages/epo/detail.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "ePO Center",
        "title": "ePO CENTER"
      },
      {
        "path": "/epo",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__epo__index' */'@/pages/epo/index.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "ePO Center",
        "title": "ePO CENTER"
      },
      {
        "path": "/epo/overview",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__epo__overview' */'@/pages/epo/overview.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "ePO Center",
        "title": "ePO CENTER"
      },
      {
        "path": "/epo/poa",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__epo__poa' */'@/pages/epo/poa.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "ePO Center",
        "title": "ePO CENTER"
      },
      {
        "path": "/epo/vat",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__epo__vat' */'@/pages/epo/vat.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "ePO Center",
        "title": "VAT"
      },
      {
        "path": "/forgot",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__forgot__index' */'@/pages/forgot/index.tsx')})
      },
      {
        "path": "/",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index' */'@/pages/index.tsx')})
      },
      {
        "path": "/login",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__index' */'@/pages/login/index.tsx')}),
        "title": "API CENTER"
      },
      {
        "path": "/masterData/gts",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__masterData__gts' */'@/pages/masterData/gts.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "Product GTS",
        "title": "PRODUCT GTS"
      },
      {
        "path": "/masterData",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__masterData__index' */'@/pages/masterData/index.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "Product",
        "title": "PRODUCT"
      },
      {
        "path": "/masterData/price",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__masterData__price' */'@/pages/masterData/price.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "Product Price",
        "title": "PRODUCT PRICE"
      },
      {
        "path": "/masterData/productData",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__masterData__productData' */'@/pages/masterData/productData.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "Product",
        "title": "PRODUCT"
      },
      {
        "path": "/masterData/risk",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__masterData__risk' */'@/pages/masterData/risk.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "High Risk",
        "title": "HIGH RISK"
      },
      {
        "path": "/masterData/stock",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__masterData__stock' */'@/pages/masterData/stock.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "Product Stock",
        "title": "PRODUCT STOCK"
      },
      {
        "path": "/masterData/temp",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__masterData__temp' */'@/pages/masterData/temp.tsx')})
      },
      {
        "path": "/masterData/variance",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__masterData__variance' */'@/pages/masterData/variance.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "Product Variance",
        "title": "Product Variance"
      },
      {
        "path": "/promotions",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotions__index' */'@/pages/promotions/index.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "Promotion",
        "title": "Promotions"
      },
      {
        "path": "/promotions/newPromotions",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__promotions__newPromotions' */'@/pages/promotions/newPromotions.tsx')})
      },
      {
        "path": "/setting/changePassword",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__changePassword' */'@/pages/setting/changePassword.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})]
      },
      {
        "path": "/setting/createNewUser",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__createNewUser' */'@/pages/setting/createNewUser.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})]
      },
      {
        "path": "/setting/customerThresholdInfo",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__customerThresholdInfo' */'@/pages/setting/customerThresholdInfo.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "User Management",
        "title": "Customer Threshold Info"
      },
      {
        "path": "/setting/role",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__role' */'@/pages/setting/role.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "Role & Authority Management",
        "title": "Role & Authority Management"
      },
      {
        "path": "/setting/user",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setting__user' */'@/pages/setting/user.tsx')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
        "functionName": "User Management",
        "title": "User Management"
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404.tsx')})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
