import request from './http';

export function GetMonthCustomerAPIUsageSummary(data: any) {
  return request({
    url: '/v1/CustomerMonthlyAPIUsage/GetCustomerAPIUsageSummary',
    method: 'POST',
    data,
  });
}

export function GetMonthCustomerAPIRightChartData(data: any) {
  return request({
    url: '/v1/CustomerMonthlyAPIUsage/GetCustomerAPIRightChartData',
    method: 'POST',
    data,
  });
}
//
export function CustomerMonthlyAPIUsage(data: any) {
  return request({
    url: '/v1/CustomerMonthlyAPIUsage',
    method: 'POST',
    data,
  });
}

export function GetYearCustomerAPIRightChartData(data: any) {
  return request({
    url: '/v1/CustomerYearlyAPIUsage/GetCustomerAPIRightChartData',
    method: 'POST',
    data,
  });
}

export function CustomerYearlyAPIUsage(data: any) {
  return request({
    url: '/v1/CustomerYearlyAPIUsage',
    method: 'POST',
    data,
  });
}
export function GetYearCustomerAPIUsageSummary(data: any) {
  return request({
    url: '/v1/CustomerYearlyAPIUsage/GetCustomerAPIUsageSummary',
    method: 'post',
    data,
  });
}
export function CustomerQuarterlyAPIUsage(data: any) {
  return request({
    url: '/v1/CustomerQuarterlyAPIUsage',
    method: 'POST',
    data,
  });
}
export function GetQuarterCustomerAPIUsageSummary(data: any) {
  return request({
    url: '/v1/CustomerQuarterlyAPIUsage/GetCustomerAPIUsageSummary',
    method: 'POST',
    data,
  });
}
export function GetQuarterCustomerAPIRightChartData(data: any) {
  return request({
    url: '/v1/CustomerQuarterlyAPIUsage/GetCustomerAPIRightChartData',
    method: 'POST',
    data,
  });
}

export function GetDailyCustomerAPIRightChartData(data: any) {
  return request({
    url: '/v1/CustomerDailyAPIUsage/GetCustomerAPIRightChartData',
    method: 'POST',
    data,
  });
}
export function GetDailyCustomerAPIUsageSummary(data: any) {
  return request({
    url: '/v1/CustomerDailyAPIUsage/GetCustomerAPIUsageSummary',
    method: 'POST',
    data,
  });
}
export function CustomerDailyAPIUsage(data: any) {
  return request({
    url: '/v1/CustomerDailyAPIUsage',
    method: 'POST',
    data,
  });
}

export function GetDashboardDropdownlists(data: any) {
  return request({
    url: '/v1/Customer/GetDashboardDropdownlists',
    method: 'GET',
    data,
  });
}

export function GetCustomersByGroups(data: any) {
  return request({
    url: '/v1/Customer/GetCustomersByGroups',
    method: 'post',
    data,
  });
}
export function DownloadCustomerDailyAPIUsage(data: any) {
  return request({
    url: '/v1/CustomerDailyAPIUsage/DownloadCustomerDailyAPIUsage',
    method: 'post',
    responseType: 'blob',
    data,
  });
}

export function DownloadCustomerMonthlyAPIUsage(data: any) {
  return request({
    url: '/v1/CustomerMonthlyAPIUsage/DownloadCustomerMonthlyAPIUsage',
    method: 'post',
    responseType: 'blob',
    data,
  });
}

export function DownloadCustomerQuarterlyAPIUsage(data: any) {
  return request({
    url: '/v1/CustomerQuarterlyAPIUsage/DownloadCustomerQuarterlyAPIUsage',
    method: 'post',
    responseType: 'blob',
    data,
  });
}

export function DownloadCustomerYearlyAPIUsage(data: any) {
  return request({
    url: '/v1/CustomerYearlyAPIUsage/DownloadCustomerYearlyAPIUsage',
    method: 'post',
    responseType: 'blob',
    data,
  });
}
