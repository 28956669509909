import request from './http';

export function apiAccess(data: any) {
  return request({
    url: '/api/customer/apiAccess',
    method: 'post',
    data,
  });
}
export function productAccess(data: any) {
  return request({
    url: '/api/customer/productAccess',
    method: 'post',
    data,
  });
}
export function GetCustomerList(data: any) {
  return request({
    url: '/v1/Customer/GetCustomerList',
    method: 'post',
    data,
  });
}
export function GetCustomerSoldTo(data: any) {
  return request({
    url: '/v1/Customer/GetCustomerSoldToById',
    method: 'GET',
    params: data,
  });
}

export function CreateCustomerProfile(data: any) {
  return request({
    url: '/v1/Customer/CreateCustomerProfile',
    method: 'post',
    data,
  });
}

export function DownloadCustomerList(data: any) {
  return request({
    url: '/v1/Customer/DownloadCustomerList',
    method: 'post',
    responseType: 'blob',
    data,
  });
}

export function DownloadCustomerAPI(data: any) {
  return request({
    url: '/v1/Customer/DownloadCustomerAPI',
    method: 'post',
    responseType: 'blob',
    data,
  });
}

export function UpdateCustomerProfile(data: any) {
  return request({
    url: '/v1/Customer/UpdateCustomerProfile',
    method: 'post',
    data,
  });
}
export function UpdateCustomerAPIAccessMapping(data: any) {
  return request({
    url: '/v1/Customer/UpdateCustomerAPIAccessMapping',
    method: 'post',
    data,
  });
}
export function UpdateCustomerProductAccess(data: any) {
  return request({
    url: '/v1/Customer/UpdateCustomerProductAccess',
    method: 'post',
    data,
  });
}
export function GetCustomerDetailById(data: any) {
  return request({
    url: '/v1/Customer/GetCustomerDetailById',
    method: 'GET',
    params: data,
  });
}
export function GetCustomerAPIAccessMappingById(data: { CustomerId: any }) {
  return request({
    url: '/v1/Customer/GetCustomerAPIAccessMappingById',
    method: 'GET',
    params: data,
  });
}
export function GetCustomerProductAccessById(data: any) {
  return request({
    url: '/v1/Customer/GetCustomerProductAccessById',
    method: 'GET',
    params: data,
  });
}
export function GetCustomerAPIOverview(data: any) {
  return request({
    url: '/v1/Customer/GetCustomerAPIOverview',
    method: 'GET',
    params: data,
  });
}
export function GetSystemBaseInfoDropdown(data: any) {
  return request({
    url: '/v1/SystemBaseInfo/GetSystemBaseInfoDropdown',
    method: 'GET',
    params: data,
  });
}
export function CreateCustomerGroup(data: any) {
  return request({
    url: '/v1/SystemBaseInfo/CreateCustomerGroup',
    method: 'post',
    data,
  });
}
export function updateCredentials(data: any) {
  return request({
    url: '/v1/Customer/UpdateCustomerCredentials',
    method: 'post',
    data,
  });
}
export function getProductGTSInfoList(data: any) {
  return request({
    url: '/v1/GTS/GetProductGTSInfoList',
    method: 'post',
    data,
  });
}
