export default {
  LOGIN_ACCOUNT: 'Login Account',
  USER_ID: 'User ID',
  PASSWORD: 'Password',
  PLEASE_INPUT_PASSWORD: 'Please input password',
  SIGN_IN: 'Sign in',
  FORGET_PASSWORD: 'Forget Password?',
  FORGET_YOUR_PASSWORD: 'Forget Your Password?',
  FORGET_PASSWORD_INTRO: 'Please contact below Administrators for assistance.',
  FORGET_PASSWORD_PLACEHOLDER1: 'Please input your User Account here',
  FORGET_PASSWORD_PLACEHOLDER2: 'Please input your Email here',
  API_DASHBOARD: 'API Dashboard',
  API_DOCUMENT: 'API Document',
  EPO_CENTER: 'ePO Center',
  MASTER_DATA: 'Master Data',
  PRODUCT: 'Product',
  PRODUCT_DATA: 'Product Data',
  PRODUCT_PRICE: 'Product Price',
  PRODUCT_STOCK: 'Product Stock',
  PRODUCT_GTS: 'Product GTS',
  PRODUCT_VARIANCE: 'Product Variance',
  HIGH_RISK: 'High Risk',
  CUSTOMER_PROFILE: 'Customer Profile',
  SETTING: 'Setting',
  USER_MANAGEMENT: 'User Management',
  ROLE_AUTHORITY_MANAGEMENT: 'Role & Authority Management',
  SUBMIT: 'Submit',
  RESET_PASSWORD: 'Reset Password',
  INPUT_YOUR_PASSWORD: 'Please input your new password!',
  PROMOTIONS: 'Promotions',
  CUSTOMER_DASHBOARD: 'Customer Dashboard',
  CUSTOMER_THRESHOLD: 'Customer Threshold Info',
  SAVE: 'Save',
  DONE: 'Done',
};
