import { GetDashboardDropdownlists } from '@/http/dashboard';
import { message } from 'antd';

const DashboardModel = {
  // 命名空间 使用dispatch 路径 是 namespace / effectsName 例如 'sample/fetch' 或者 reducersName 例如 'sample/save'
  namespace: 'dashboard',
  // state 状态管理
  state: {
    customerGroups: [],
    customers: [],
    apiUsage: [],
    apiUsageSummary: [],
    rightChartData: [],
  },
  // 常见操作，多用于增删查改的数组操作
  reducers: {
    // 保存所有的内容到Model
    save(state, { payload: { data: resData } }) {
      console.log(state);
      return {
        ...state,
        customerGroups: resData.customerGroups,
        customers: resData.customers,
      };
    },
  },
  // 用于数据获取处理
  effects: {
    // 这里 action是{} 用于 dispatch({type:'',action}), call 是执行某个函数(参数1,是个方法，参数2，是向参数1传入的参数)，put 执行的reducer方法，select 选择state
    *fetchDropdown(action: Action, { call, put, select }) {
      const { data, headers } = yield call(
        GetDashboardDropdownlists, //接口方法//传入的参数
      ); //返回的data
      console.log('getDropDown', data);
      if (data.code == '0') {
        const res = data.data;
        yield put({
          type: 'save',
          payload: {
            data: res,
          },
        });
      } else {
        message.error(data.msg);
      }
    },
    *fetchData(action: Action, { call, put, select }) {
      const { data, headers } = yield call(
        GetDashboardDropdownlists, //接口方法//传入的参数
      ); //返回的data
      console.log('getDropDown', data);
      if (data.code == '0') {
        const res = data.data;
        yield put({
          type: 'save',
          payload: {
            data: res,
          },
        });
      } else {
        message.error(data.msg);
      }
    },
  },
  subscriptions: {},
};

export default DashboardModel;
