import { GetCustomerAPIOverview } from '@/http/customer';
import { message } from 'antd';

const SampleModel = {
  // 命名空间 使用dispatch 路径 是 namespace / effectsName 例如 'sample/fetch' 或者 reducersName 例如 'sample/save'
  namespace: 'sample',
  // state 状态管理
  state: {},
  // 常见操作，多用于增删查改的数组操作
  reducers: {
    // 保存所有的内容到Model
    save(state, { payload: { data: resData } }) {
      return { ...state, resData: resData };
    },
  },
  // 用于数据获取处理
  effects: {
    // 这里 action是{} 用于 dispatch({type:'',action}), call 是执行某个函数(参数1,是个方法，参数2，是向参数1传入的参数)，put 执行的reducer方法，select 选择state
    *fetch(action: Action, { call, put, select }) {
      const { data, headers } = yield call(
        GetCustomerAPIOverview,
        action, //接口方法//传入的参数
      ); //返回的data
      if (data.code == '0') {
        const resData = data.data;
        yield put({
          type: 'save',
          payload: {
            data: resData,
          },
        });
      } else {
        message.error(data.msg);
      }
    },
  },
  // 订阅监听，比如我们监听路由，进入页面就如何，可以在这写
  subscriptions: {},
};

export default SampleModel; //输出本model ，会被umijs 自动加载
